<template>
	<div>
		<div :class="`input-group form-group${ (missing.youthage && !salon.youthage) ? ' required' : '' }`" v-if="$store.getters.salonAcceptsChildren()">
			<div class="input-group-prepend">
				<span class="input-group-text">{{$t("administration.youthlimit")}}</span>
			</div>
			<ValidationInput class="form-control" type="positiveInt" nullable v-model="salon.youthage" @valid="$emit('valid', ['youthage', $event])" />
		</div>
		<div :class="`input-group form-group${ (missing.name && !salon.name) ? ' required' : '' }`">
			<div class="input-group-prepend">
				<span class="input-group-text">{{$t("administration.salonname")}}</span>
			</div>
			<input class="form-control" type="text" v-model="salon.name">
		</div>
	</div>
</template>

<script>
/*
	import Draggable from 'vuedraggable'
	import VueTimepicker from 'vue2-timepicker'
	import 'vue2-timepicker/dist/VueTimepicker.css'
	import Modal from '../components/Modal.vue'
	import ServiceButton from '../components/ServiceButton.vue'
	import StatusButton from '../components/StatusButton.vue'
	import ToggleSwitch from '../components/ToggleSwitch.vue'
	import Confirm from "../modals/Confirm.vue"
	import Popper from "vue-popperjs"
	import constants from "../constants.js"
*/
	export default {
		components: {
/*
			Draggable,
			VueTimepicker,
			Popper,
			Modal,
			Confirm,
			ToggleSwitch,
			ServiceButton,
			StatusButton
*/
		},
		data() {
			return {
				salon: Object.assign({
					name: "",
					youthage: null,
				}, this.$store.state.salon),
				missing: {}
			}
		},
		methods: {
			save() {
				return new Promise( ( resolve, err ) => {
					this.missing = {}
					let patch = {};
					[ "name", "youthage" ].forEach( key => {
						if (!this.$store.getters.salonAcceptsChildren() && key === 'youthage')
							return

						if ( !this.salon[key] || this.salon[key].length == 0 ) {
							this.missing[key] = true
						} else if ( this.salon[key] != this.$store.state.salon[key] ) {
							patch[key] = this.salon[key]
						}
					})
					if ( Object.keys( this.missing ).length > 0 ) {
						err()
					} else if ( Object.keys( patch ).length > 0 ) {
						this.$api.salon.patch( this.$store.state.salon.id, patch ).then( resp => {
							resolve()
						}).catch( error => {
							console.log( error )
							err( error.response.data.error )
						})
					} else {
						resolve()
					}
				})
			}
		},
		watch: {
		},
		mounted() {
		}
	}
</script>

<style scoped lang="scss" src="../../css/modals/editsalon.scss"/>

