<template>
	<div>
		<table id="team" class="mobile-table table-sticky">
			<thead>
				<tr>
					<th class="child1">{{$t("common.name")}}</th>
					<th class="child8">{{$t("common.usern")}}<br/>{{$t("common.emailphone")}}</th>
					<th class="child2">{{$t("common.attend")}}</th>
			<!--	<th class="child7">{{$t("common.worksch")}}</th> -->
					<th class="child3">{{$t("administration.notsup")}}</th>
               		<th class="child5">{{$t("common.color")}}</th>
					<th class="child6">{{$t("common.action")}}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="worker in workers"
					:key="worker.id"
					:class="['staff', 'noarrow', ($store.getters.salonHasUniqueAccount() || activeWorkers.indexOf(worker.id) >= 0) && 'active', worker.username==='general' && 'salon']" 
					@click="/*toggleWorkerActive(worker)*/"
				>
					<td class="mobile mobile-color">
						<div class="cadre-color" :style="{ background: constants.themes[ worker.theme ] }"></div>
						<div class="cadre-color" :style="{ background: worker.color }"></div>
					</td>
					<td class="child1 mobile-50">
						{{ worker.username === 'general' ? $store.state.salon.name : worker.fname }}
						<div v-if="worker.manager" class="desktop text-muted">
							{{ $t('common.manageracc') }}
						</div>
						<div v-else-if="worker.admin" class="desktop text-muted">
							{{ $t('common.adminacc') }}
						</div>
						<div v-else-if="worker.username === 'general'" class="desktop text-muted">
							{{ $t('common.generalacc') }}
						</div>
					</td>
					<td class="child8 mobile-100">
						<div class="d-flex">
							<div class="">{{worker.username === "general" ? $t("common.enterprise") : worker.username}}</div>
							<a :href="`mailto:${worker.email}`">{{worker.email}}</a>
							<a :href="`tel:${worker.phone}`">{{worker.phone}}</a>
						</div>
					</td>
					<td v-if="worker.manager" class="mobile mobile-100 text-muted">
						{{ $t('common.manageracc') }}
					</td>
					<td v-else-if="worker.admin" class="mobile mobile-100 text-muted">
						{{ $t('common.adminacc') }}
					</td>
					<td v-else-if="worker.username === 'general'" class="mobile mobile-100 text-muted">
						{{ $t('common.generalacc') }}
					</td>
					<td class="child2 schedule mobile-100">
						<div v-for="i in 7" class="checkbox checkbox-primary">
							<input :checked="worker.sched[i - 1] !== undefined" class=" styled d-none" type="checkbox">
							<label class="d-inline-block day" for="_">
								<div class="checkbox-text">{{ $t(`time.shortweekdays[${i - 1}]`) }}</div>
							</label>
							<vue-markdown class="d-inline-block hours" v-bind:source="worker.sched[i - 1]"></vue-markdown>
						</div>
					</td>
			<!--	<td class="child7"></td> -->
					<td class="child3" :class="{ desktop: !(worker.disabled_services || []).filter(s => s).length }">
						<div class="mobile notsup">{{$t("administration.notsup")}}</div>
						<div>
							<span v-for="service in (worker.disabled_services || [])" v-if="service">
								{{ $store.getters.getService( service ).title }}
							</span>
						</div>
					</td>
					<td class="child5 desktop">
						<div class="cadre-color" :style="{ background: constants.themes[ worker.theme ] }"></div>
						<div class="cadre-color" :style="{ background: worker.color }"></div>
					</td>
					<td class="child6 td-button mobile-100">
						<button class="btn-mod fas fa-edit btn btn-outline-success" @click="showEditModal = worker" type="button"></button>
					</td>
				</tr>
				<tr v-if="!$store.getters.salonHasFixedAccounts()" id="btn-add2">
					<td class="td-button add-presta mobile-100" colspan="12">
						<button
							id="add-presta"
							class="btn-add fas fa-plus btn btn-primary"
							type="button"
							@click="editStaff( null )"
						/>
					</td>
				</tr>
			</tbody>
		</table>
		<EditStaff v-if="showEditModal" :technic="false" :data="showEditModal" :title="$t('common.staff')" v-on:close="showEditModal = false"/>
	</div>
</template>

<script>
	import VueMarkdown from 'vue-markdown'
	import EditStaff from '../../modals/EditStaff.vue'
	import constants from '../../constants.js'
	
	export default {
		components: {
			VueMarkdown,
			EditStaff
		},
		data() {
			return {
				constants: constants(this.$store.state.domain),
				showEditModal: false,
				activeWorkers: []
			}
		},
		computed: {
			workers() {
				var mainSchedule = this.$store.getters.getSchedules().sort( ( a, b ) => a.id - b.id )
				var mainScheduleGeneral = []
				for ( var j = 0; j < 7; j++ ) {
					if ( mainSchedule && mainSchedule[j] ) {
						var sched = mainSchedule[j]
						mainSchedule[j].morningop = this.hourNumber(sched.morningop)
						mainSchedule[j].morningclos = this.hourNumber(sched.morningclos)
						mainSchedule[j].afternop = this.hourNumber(sched.afternop)
						mainSchedule[j].afternclos = this.hourNumber(sched.afternclos)
						if ( sched.morningop || sched.morningclos || sched.afternop || sched.afternclos ) {
							mainScheduleGeneral[j] = []
							if ( sched.morningop && sched.morningclos ) {
								mainScheduleGeneral[j].push( "**" + this.hourNumber( sched.morningop ) + "** - " + this.hourNumber( sched.morningclos ) )
							}
							if ( sched.afternop && sched.afternclos ) {
								mainScheduleGeneral[j].push( "**" + this.hourNumber( sched.afternop ) + "** - " + this.hourNumber( sched.afternclos ) )
							}
							mainScheduleGeneral[j] = ( mainScheduleGeneral[j].length > 0 ? " - " : "" ) + mainScheduleGeneral[j].join( " / " )
						} else {
						//	mainSchedule[j] = undefined
							mainScheduleGeneral[j] = undefined
						}
					}
				}

				var workers = this.$store.getters.getWorkers()
				workers.push( ...workers.splice( workers.findIndex( w => w.username === 'general' ), 1 ) )
				for ( var i = 0; i < workers.length; i++ ) {
					if ( this.$store.getters.salonHasUniqueAccount() || workers[i].username == "general" ) {
						workers[i].sched = mainScheduleGeneral
					} else if ( workers[i].schedule && workers[i].schedule.length > 0 ) {
						workers[i].sched = []
						for ( var j = 0; j < workers[i].schedule.length; j++ ) {
							var sched = workers[i].schedule[j]
							var day = sched[0] - 1
							if ( sched.length > 1 && ( mainSchedule[day].morningop || mainSchedule[day].morningclos || mainSchedule[day].afternop || mainSchedule[day].afternclos ) ) {
								workers[i].sched[day] = []
								if ( sched[1] === 0 && sched[2] === 0 && sched[3] === 0 && sched[4] === 0 ) {
									workers[i].sched[day] = undefined
								} else {
									let morningop = sched[1] !== null ? this.hourString( sched[1] ) : null
									let morningclos = sched[2] !== null ? this.hourString( sched[2] ) : null
									let afternop = sched[3] !== null ? this.hourString( sched[3] ) : null
									let afternclos = sched[4] !== null ? this.hourString( sched[4] ) : null
									if ( sched[1] === null && ( sched[2] !== null || sched[3] !== null || sched[4] !== null ) ) {
										morningop = morningop || mainSchedule[day].morningop
									}
									if ( sched[2] === null && ( sched[1] !== null || sched[3] !== null || sched[4] !== null ) ) {
										morningclos = morningclos || mainSchedule[day].morningclos
									}
									if ( sched[3] === null && ( sched[1] !== null || sched[2] !== null || sched[4] !== null ) ) {
										afternop = afternop || mainSchedule[day].afternop
									}
									if ( sched[4] === null && ( sched[1] !== null || sched[2] !== null || sched[3] !== null ) ) {
										afternclos = afternclos || mainSchedule[day].afternclos
									}
									if ( morningop === "00:00" ) {
										morningop = null
									}
									if ( morningclos === "00:00" ) {
										morningclos = null
									}
									if ( afternop === "00:00" ) {
										afternop = null
									}
									if ( afternclos === "00:00" ) {
										afternclos = null
									}
									if ( morningop && morningclos ) {
										workers[i].sched[day].push( morningop + " - " + morningclos )
									}
									if ( afternop && afternclos ) {
										workers[i].sched[day].push( afternop + " - " + afternclos )
									}
									workers[i].sched[day] = ( workers[i].sched[day].length > 0 ? " - " : "*" + this.$t("administration.fullday") + "*" ) + workers[i].sched[day].join( " / " )
								}
							} else {
								workers[i].sched[day] = undefined
							}
						}
					} else {
						workers[i].sched = mainSchedule.map( sched => ( sched.morningop || sched.morningclos || sched.afternop || sched.afternclos ) ? "" : undefined )
					}
				}

				if ( this.showEditModal ) {
					for ( var i = 0; i < workers.length; i++ ) {
						if ( workers[i].id === this.showEditModal.id ) {
							this.showEditModal = workers[i]
							break
						}
					}
				}
	
				return workers
			}
		},
		methods: {
			hourString( hour ) {
				var h = Math.floor( hour / 60 )
				var m = hour - h * 60
				if ( h < 10 ) { h = "0" + h }
				if ( m < 10 ) { m = "0" + m }
				return h + ":" + m
			},
			hourNumber( str ) {
				if ( str && str.match ) {
					var matches = str.match( /(\d*):(\d*)/ )
					if ( matches != null ) {
						return this.hourString( parseInt( matches[1] ) * 60 + parseInt( matches[2] ) )
					}
				}
				return null
			},
			editStaff( id ) {
				this.showEditModal = true
			},
			toggleWorkerActive({ id }) {
				const index = this.activeWorkers.indexOf(id)
				if (index >= 0)
					this.activeWorkers.splice(index)
				else
					this.activeWorkers.push(id)
			}
		}
	}
</script>
