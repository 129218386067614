<template>
	<Modal name="edit-staff">
		<template v-slot:header>
			<h1 class="title-search">{{$t("common.staff")}}</h1>
		</template>
		<template v-slot:content>
			<div class="modal-content">
				<form class="form">
					<!--div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.id")}}</span>
						</div>
						<input class="form-control" disabled type="text" v-model="staff.id">
					</div-->
					<template v-if="isSalon">
						<h2 class="title-search2-2">{{$t("common.salon")}}</h2>
						<EditSalon ref="editSalon" @valid="valid[$event[0]] = $event[1]" />
					</template>
					<template v-if="data && data.username !== 'general'">
						<div class="">
							<h2 id="title-infos">{{$t("common.infos")}}</h2>
						</div>
						<div :class="`input-group form-group${ (missing.fname && !staff.fname) ? ' required' : '' }`">
							<div class="input-group-prepend">
								<span class="input-group-text">{{$t("common.fname")}}</span>
							</div>
							<input class="form-control" type="text" v-model="staff.fname">
						</div>
						<div :class="`input-group form-group${ (missing.lname && !staff.lname) ? ' required' : '' }`">
							<div class="input-group-prepend">
								<span class="input-group-text">{{$t("common.lname")}}</span>
							</div>
							<input class="form-control" type="text" v-model="staff.lname">
						</div>
					</template>
					<div class="">
						<h2 id="title-security">{{$t("common.secu")}}</h2>
					</div>
					<div :class="`input-group form-group${ (missing.username && !staff.username) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.usern")}}</span>
						</div>
						<input :disabled="data && data.username === 'general'" class="form-control" type="text" v-model="staff.username">
					</div>
					<div :class="`input-group form-group${ (missing.email && !staff.email) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.email")}}</span>
						</div>
						<input class="form-control" type="text" v-model="staff.email">
					</div>
					<div :class="`input-group form-group${ (missing.phone && !staff.phone) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.phone")}}</span>
						</div>
						<input class="form-control" type="text" autocomplete="off" v-model="staff.phone">
					</div>
					<div id="password-block" class="input-group form-group">
						<div class="input-group-prepend v-mobile">
							<span class="input-group-text">{{$t("common.npasswd")}}</span>
						</div>
						<div>
							<input class="form-control" name="password" placeholder="Nouveau mot de passe" type="password" autocomplete="off" v-model="password">
							<input class="form-control" name="password-confirm" placeholder="Confirmer nouveau mot de passe" type="password" autocomplete="off" v-model="passwordConfirm">
						</div>
					</div>
					<div v-if="!data || data.username !== 'general'" class="input-group form-group">
						<div class="input-group-prepend admin">
							<span class="input-group-text">{{$t("common.adminacc")}}</span>
						</div>
						<div class="switch">
						<ToggleSwitch v-model="staff.admin" :disabled="!canChangeAdmin"></ToggleSwitch>
						</div>
					</div>
				</form>
				<div id="schedules">
					<h2 class="title-search2-2">{{$t("common.attend")}}</h2>
					<div>
						<div v-for="( sched, i ) in schedule.filter(sched => sched !== 'disabled')" :key="i">
							<h4 class="text-center days">{{ $t(`time.weekdays[${i}]`) }}</h4>
							<div class="row">
								<div class="col-6">
									<div class="checkbox checkbox-primary text-center">
										<input :id="'schedm' + i" v-model="sched.morning" class="styled d-none" type="checkbox" @change="scheduleChanged = true">
										<label class="d-inline-block day" :for="'schedm' + i">
											<div class="checkbox-text">{{ $t("time.morning" )}}</div>
										</label>
									</div>
									<div class="form-group input-group" :class="{ error: !isSchedValid(sched, 'morning') }">
										<vue-timepicker
											:hide-clear-button="isSalon"
											input-class="form-control"
											:hide-disabled-items="true"
											:disabled="!sched.morning"
											:hour-range="isSalon ? undefined : [[timeHour(sched.morningopMain), timeHour(sched.morningclosMain)]]"
											:minute-range="minuteRange(sched.morningop,sched.morningopMain,sched.morningclosMain)"
											:placeholder="sched.morning ? sched.morningopMain : ' '"
											v-model="sched.morningop"
											@change="scheduleChanged=true"
										></vue-timepicker>
										<vue-timepicker
											:hide-clear-button="isSalon"
											input-class="form-control"
											:hide-disabled-items="true"
											:disabled="!sched.morning"
											:hour-range="isSalon ? undefined : [[timeHour(sched.morningopMain), timeHour(sched.morningclosMain)]]"
											:minute-range="minuteRange(sched.morningclos,sched.morningopMain,sched.morningclosMain)"
											:placeholder="sched.morning ? sched.morningclosMain : ' '"
											v-model="sched.morningclos"
											@change="scheduleChanged=true"
										></vue-timepicker>
									</div>
								</div>
								<div class="col-6">
									<div class="checkbox checkbox-primary text-center">
										<input :id="'scheda' + i" v-model="sched.afternoon" class="styled d-none" type="checkbox" @change="scheduleChanged = true">
										<label class="d-inline-block day" :for="'scheda' + i">
											<div class="checkbox-text">{{ $t("time.afternoon" )}}</div>
										</label>
									</div>
									<div class="form-group input-group" :class="{ error: !isSchedValid(sched, 'afternoon') }">
										<vue-timepicker
											:hide-clear-button="isSalon"
											input-class="form-control"
											:hide-disabled-items="true"
											:disabled="!sched.afternoon"
											:hour-range="isSalon ? undefined : [[timeHour(sched.afternopMain), timeHour(sched.afternclosMain)]]"
											:minute-range="minuteRange(sched.afternop,sched.afternopMain,sched.afternclosMain)"
											:placeholder="sched.afternoon ? sched.afternopMain : ' '"
											v-model="sched.afternop"
											@change="scheduleChanged=true"
										></vue-timepicker>
										<vue-timepicker
											:hide-clear-button="isSalon"
											input-class="form-control"
											:hide-disabled-items="true"
											:disabled="!sched.afternoon"
											:hour-range="isSalon ? undefined : [[timeHour(sched.afternopMain), timeHour(sched.afternclosMain)]]"
											:minute-range="minuteRange(sched.afternclos,sched.afternopMain,sched.afternclosMain)"
											:placeholder="sched.afternoon ? sched.afternclosMain : ' '"
											v-model="sched.afternclos"
											@change="scheduleChanged=true"
										></vue-timepicker>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="false" id="special-hours">
					<h2 class="title-search2-2">{{$t("settings.specialhours")}}</h2>
					<div class="text-muted">{{ $t("settings.specialhourstip") }}</div>
					<div class="content">
						<div class="line">
							<span>{{ $t("settings.specialmorning") }} : </span>
							<input id="special-morning" class="styled" type="number" v-model="staff.overflow_open"/>
						</div>
						<div class="line">
							<span>{{ $t("settings.specialevening") }} : </span>
							<input id="special-evening" class="styled" type="number" v-model="staff.overflow_close"/>
						</div>
					</div>
				</div>
				<div v-if="!$store.getters.salonHasUniqueAccount() && data.username != 'general'">
					<h2 class="title-search2-2">{{$t("settings.group")}}</h2>
					<div class="row group-block">
						<Popper
							ref="groupPopper"
							class="dropdown"
							trigger="clickToToggle"
							:options="{ placement: 'bottom' }"
							:visible-arrow="false"
						>
							<div class="dropdown-menu">
								<div class="dropdown-choices">
									<button
										v-for="group in [null, ...$store.state.groups, { id: -1, name: $t('settings.newgroup') }]"
										:key="group && group.id"
										class="dropdown-item light"
										type="button"
										@click="staff.groupid = group && group.id; $refs.groupPopper.doClose()"
									>
										{{ !group ? $t("settings.nogroup") : group.name }}
									</button>
								</div>
							</div>
							<button
								slot="reference"
								ref="colorButton"
								aria-expanded="false"
								aria-haspopup="true"
								data-flip="false"
								data-toggle="dropdown"
								trigger="none"
								class="btn btn-secondary dropdown-toggle group-field"
							>
								<span v-if="staff.groupid === null">
									{{ $t("settings.nogroup") }}
								</span>
								<span v-else-if="staff.groupid < 0">
									{{ $t('settings.newgroup') }}
								</span>
								<span v-else>
									{{ ($store.state.groups.find(group => group.id == staff.groupid) || {}).name }}
								</span>
							</button>
						</Popper>
						<div class="form" v-if="staff.groupid < 0">
							<div class="input-group form-group" :class="{ required: missing.newGroupName }">
								<div class="input-group-prepend">
									<span class="input-group-text">{{$t("settings.groupname")}}</span>
								</div>
								<input class="form-control" type="text" v-model="newGroupName">
							</div>
						</div>
					</div>
				</div>
				<div>
					<h2 class="title-search2-2">{{$t("settings.themes")}}</h2>
					<div class="row color-block">
						<div class="col-md-6 d-flex">
							<Popper
								ref="colorPopper"
								class="dropdown"
								trigger="clickToToggle"
								:options="{ placement: 'bottom' }"
								:visible-arrow="false"
							>
								<div class="dropdown-menu">
									<div class="dropdown-choices">
										<button
											v-for="(color, name) in themes"
											:key="'theme-' + name"
											:class="{ 'dropdown-item': true, disabled: themeAlreadyUsed(name) }"
											type="button"
											:value="name"
											v-on:click="themeAlreadyUsed(name) ? null : changeTheme(name)"
										>
											<div class="cadre-color" :style="'background:' + color">
												<span v-if="themeAlreadyUsed(name)">{{ themeUsedInitials(name) }}</span>
											</div>
										</button>
									</div>
								</div>
								<button
									id="account-color-btn"
									slot="reference"
									ref="colorButton"
									aria-expanded="false"
									aria-haspopup="true"
									data-flip="false"
									data-toggle="dropdown"
									trigger="none"
									class="btn btn-secondary dropdown-toggle theme-field"
									:class="{ required: missing.theme && !staff.theme }"
								>
									<div id="edit-staff-account-color" class="cadre-color" :key="themes[staff.theme]" :style="{background: themes[staff.theme] }"></div>
									<span>{{ $t("settings.acctheme") }}</span>
								</button>
							</Popper>
						</div>
						<div class="col-md-6 d-flex">
							<Popper
								ref="colorPopper"
								class="dropdown"
								trigger="clickToToggle"
								:options="{ placement: 'bottom' }"
								:visible-arrow="false"
							>
								<div class="dropdown-menu">
									<div class="dropdown-choices">
										<button
											v-for="color in colors"
											:key="color"
											:class="{ 'dropdown-item light': true, disabled: colorAlreadyUsed(color) }"
											type="button"
											:value="color"
											v-on:click = "colorAlreadyUsed(color) ? null : changeColor(color)"
										>
											<div class="cadre-color" :style="'background-color:' + color">
												<span v-if="colorAlreadyUsed(color)">{{ colorUsedInitials(color) }}</span>
											</div>
										</button>
									</div>
								</div>
								<button
									id="schedule-color-btn"
									slot="reference"
									ref="colorButton"
									aria-expanded="false"
									aria-haspopup="true"
									data-flip="false"
									data-toggle="dropdown"
									trigger="none"
									class="btn btn-secondary dropdown-toggle theme-field"
									:class="{ required: missing.color && !staff.color }"
								>
									<div id="edit-staff-schedule-color" class="cadre-color" :key="staff.color" :style="{background: staff.color }"></div>
									<span>{{ $t("settings.schtheme") }}</span>
								</button>
							</Popper>
						</div>
					</div>
				</div>
				<div v-if="!$store.getters.salonHasUniqueAccount() && data.username != 'general'">
					<h2 class="title-search2-2">{{ $t("administration.notsup") }}</h2>
					<div id="prestations-cont">
						<Draggable v-model="staff.disabled_services" :scroll-sensitivity="100" :force-fallback="true" v-on:start="drag=true" v-on:end="drag=false" class="ui-sortable" handle=".sortable">
						<!--div class="d-flex d-justify-content-center"-->
							<ServiceButton
								v-for="( service, idx ) in staff.disabled_services"
								:key="idx + '_' + service.id"
								:id="'dependency-' + service.id"
								:service="service"
								@remove="dataChanged = true; staff.disabled_services.splice( staff.disabled_services.indexOf( service ), 1 )"
								:hide="staff.disabled_services.map(s => s.id)"
							/>
						<!--/div-->
						</Draggable>
						<ServiceButton
							@selected="dataChanged = true; staff.disabled_services.push( $event ); staff.disabled_services.sort( ( a, b ) => a.priority - b.priority )"
							:hide="staff.disabled_services.map(s => s.id)"
						/>
					</div>
				</div>
			</div>
			<Confirm
				ref="confirmPassword"
				name="edit-password-confirm"
				v-if="confirmPassword"
				@confirm="doSave(confirmPassword.callback, confirmPassword.closeAfter); confirmPassword=null"
				@close="confirmPassword.callback(-1); confirmPassword=null; passwordCheck=''"
			>
				{{ $t('administration.passedit') }}
				<div class="form-group input-group">
					<input class="form-control" type="password" :placeholder="$t('common.pwd')" v-model="passwordCheck">
				</div>
			</Confirm>
		</template>
		<template v-slot:buttons>
			<StatusButton
				v-if="data && Object.keys(data).length > 0 && !$store.getters.salonHasUniqueAccount() && data.username !== 'general' && !data.manager"
				id="delete"
				type="danger"
				alert="modal"
				@click="clickDeleteButton($event)"
			><i class="fas fa-trash-alt"></i></StatusButton>
			<StatusButton id="validate" type="primary" alert="modal" @click="save( $event, true )"><i class="fas fa-check"></i></StatusButton>
			<StatusButton class="desktop" id="apply" type="secondary" alert="modal" @click="save( $event, false )">{{$t("common.apply")}}</StatusButton>
			<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{$t( /*dataChanged ?"common.cancel" :*/"common.close")}}</button>
			<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
				{{ $t('pack.confirmdelete') }}
			</Confirm>
			<Modal type="small" v-if="deleteError">
				<div slot="content">
					{{ $t(deleteError) }}
				</div>
				<button slot="buttons" class="btn btn-gray" @click="deleteError = null" type="button">
					{{ $t('common.close') }}
				</button>
			</Modal>
		</template>
	</Modal>
</template>

<script>
	import Draggable from 'vuedraggable'
	import VueTimepicker from 'vue2-timepicker'
	import 'vue2-timepicker/dist/VueTimepicker.css'
	import Modal from '../components/Modal.vue'
	import ServiceButton from '../components/ServiceButton.vue'
	import StatusButton from '../components/StatusButton.vue'
	import ToggleSwitch from '../components/ToggleSwitch.vue'
	import Confirm from "../modals/Confirm.vue"
	import EditSalon from "./components/EditSalon.vue"
	import Popper from "vue-popperjs"
	import constants from "../constants.js"

	export default {
		components: {
			Draggable,
			VueTimepicker,
			Popper,
			Modal,
			Confirm,
			ToggleSwitch,
			ServiceButton,
			StatusButton,
			EditSalon
		},
		props: [
			"data"
		],
		data() {
			return {
				valid: { youthage: true },
				themes: constants(this.$store.state.domain).themes,
				colors: constants(this.$store.state.domain).colors,
				showDeleteConfirm: false,
				confirmPassword: null,
				passwordCheck: "",
				missing: {
					theme: false,
					color: false,
					fname: false,
					lname: false,
					username: false,
					email: false,
					phone: false,
					newGroupName: false,
				},
				staff: Object.assign( {
					id: this.$t( "editstaff.automatic" ),
					fname: "",
					lname: "",
					admin: false,
					disabled_services: [],
					theme: "",
					color: "",
					password: "",
					username: "",
					email: "",
					hidden: false,
					schedule: [],
					disabled_notifications: [],
					locale: "",
					phone: "",
					overflow_open: null,
					overflow_close: null,
					groupid: null,
				}, { ...this.data, disabled_services: this.data.disabled_services || [] } ),
				schedule: [],
				scheduleChanged: false,
				password: "",
				passwordConfirm: "",
				deleteError: null,
				newGroupName: '',
			}
		},
		computed: {
			isSalon() {
				return this.$store.getters.salonHasUniqueAccount() || ( this.data && this.data.username == "general" )
			},
			canChangeAdmin() {
				if (this.$store.getters.salonHasUniqueAccount() || this.data.manager || this.data.id == this.$store.state.worker.id) {
					return false
				}
				return this.$store.state.worker.admin
			},
		},
		methods: {
			isSchedValid(sched, en) {
				console.log( "isSchedValid", sched, en )
				if ((en === 'afternoon' && !sched.afternoon) || (en === 'morning' && !sched.morning))
					return true

				const getSched = (sd, mn = false) => {
					const s = sched[sd]

					if (!s && !mn)
						return getSched(sd + 'Main', true)

					if (typeof s === 'string' || !s)
						return s

					if (s.HH === '' && s.mm === '')
						return getSched(sd + 'Main', true)
					
					return s.HH + ':' + s.mm
				}

				const clos = en === 'afternoon' ? getSched('afternclos') : getSched('morningclos')
				const op = en === 'afternoon' ? getSched('afternop') : getSched('morningop')

				if (!clos || !op || !clos.match(/^\d\d:\d\d$/) || !op.match(/^\d\d:\d\d$/))
					return false

				const [closhr, closmn] = clos.split(':').map(d => parseInt(d))
				const [ophr, opmn] = op.split(':').map(d => parseInt(d))

				if ((closhr === 0 && closmn === 0) || (ophr === 0 && opmn === 0))
					return false

				if (60 * closhr + closmn <= 60 * ophr + opmn)
					return false

				if (!this.$store.getters.salonHasUniqueAccount() && this.data.username !== 'general') {

					const closMain = (en === 'afternoon' ? getSched('afternclosMain') : getSched('morningclosMain'))
					const opMain = (en === 'afternoon' ? getSched('afternopMain') : getSched('morningopMain'))

					if (!closMain || !opMain) return false

					const [closMainHr, closMainMn] = closMain.split(':').map(d => parseInt(d))
					const [opMainHr, opMainMn] = opMain.split(':').map(d => parseInt(d))

					if (60 * closhr + closmn > 60 * closMainHr + closMainMn || 60 * ophr + opmn < 60 * opMainHr + opMainMn)
						return false

				}

				if (en === 'afternoon' && this.isSchedValid(sched, 'morning') && getSched(sched.morningclos)) {
					const [closMorningHr, closMorningMn] = getSched(sched.morningclos).split(':').map(d => parseInt(d))

					console.log(60 * ophr + opmn, 60 * closMorningHr + closMorningMn)
					if (60 * ophr + opmn < 60 * closMorningHr + closMorningMn)
						return false
				}

				return true
			},
			clickDeleteButton(ev) {
				if (this.data.id == this.$store.state.worker.id) {
					ev(false, this.$t('editstaff.cannotdeleteself'))
				} else if (this.data.manager) {
					this.deleteError = 'editstaff.cannotdeletemanager'
					ev(false, this.$t('editstaff.cannotdeletemanager'))
				} else if (!this.$store.state.worker.admin) {
					ev(false, this.$t('editstaff.mustbeadmin'))
				} else {
					this.showDeleteConfirm = ev
					ev(true)
				}
			},
			themeAlreadyUsed( name ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					if ( this.$store.state.workers[i].id != this.staff.id && this.$store.state.workers[i].theme == name ) {
						return true
					}
				}
				return false
			},
			themeUsedInitials( name ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					const worker = this.$store.state.workers[i]
					if ( worker.id != this.staff.id && worker.theme == name ) {
						return ( worker.fname || "" ).substr(0, 1).toUpperCase() + ( worker.lname || "" ).substr(0, 1).toUpperCase()
					}
				}
				return ""
			},
			colorAlreadyUsed( color ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					if ( this.$store.state.workers[i].id != this.staff.id && this.$store.state.workers[i].color == color ) {
						return true
					}
				}
				return false
			},
			colorUsedInitials( color ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					const worker = this.$store.state.workers[i]
					if ( worker.id != this.staff.id && worker.color == color ) {
						return ( worker.fname || "" ).substr(0, 1).toUpperCase() + ( worker.lname || "" ).substr(0, 1).toUpperCase()
					}
				}
				return ""
			},
			changeTheme( name ) {
				this.staff.theme = name
				document.getElementById( 'edit-staff-account-color' ).style.background = color
				this.$refs.colorPopper.doClose()
			},
			changeColor( color ) {
				this.staff.color = color
				document.getElementById( 'edit-staff-schedule-color' ).style.background = color
				this.$refs.colorPopper.doClose()
			},
			timeHour( time ) {
				return parseInt( time )
			},
			minuteRange( time, min, max ) {
				if ( !time ) {
					return []
				}
				let parse = function( t ) {
					if ( !t ) {
						return { HH: 0, mm: 0 }
					}
					let s = t.split(":")
					return { HH: parseInt(s[0]), mm: parseInt(s[1]) }
				}
				let ret = []
				min = parse( min )
				max = parse( max )
				let hours = parseInt(time.HH) || 0
				let minutes = parseInt(time.mm) || 0
				if ( hours > min.HH && hours < max.HH ) {
					return undefined
				} else if ( hours == min.HH ) {
					for ( let i = min.mm; i < 60; i++ ) {
						ret.push( i )
					}
				} else if ( hours == max.HH ) {
					for ( let i = 0; i < max.mm; i++ ) {
						ret.push( i )
					}
				}
				return undefined
			},
			deleteWorker( callback ) {
				this.$api.worker.delete( this.data.id ).then( response => {
					callback( true );
					setTimeout( () => this.$emit( 'close' ), 1250 );
				}).catch( error => callback( false, error.response.data.error ) );
			},
			save( callback, closeAfter ) {
				let checkFailed = false

				Object.keys( this.missing ).forEach( field => {
					if (['fname', 'lname', 'newGroupName'].includes(field) && this.staff.username === 'general')
						return

					if (field === 'newGroupName') {
						if (this.staff.groupid >= 0)
							return
						else if (!this.newGroupName) {
							this.missing[field] = true
							checkFailed = true
							return
						} else {
							return
						}
					}

					if ( !this.staff[field] || ( this.staff[field] && this.staff[field].length == 0 ) ) {
						this.missing[field] = true
						checkFailed = true
					}
				})
				console.log( this.missing )
				if ( checkFailed ) {
					callback( false )
					return
				}

				if (this.$store.state.workers.some(w => w.username === this.staff.username && this.data.id !== w.id)) {
					callback(false, "errors.values.constraints.unique.workers_username")
					this.password = ""
					this.passwordConfirm = ""
					return
				}
				if ( this.password.length > 0 ) {
					if ( this.password !== this.passwordConfirm ) {
						callback( false, "errors.pass.mismatch" )
						this.password = ""
						this.passwordConfirm = ""
						return
					}
					this.confirmPassword = { callback, closeAfter }
				} else {
					this.doSave( callback, closeAfter )
				}
			},
			async doSave( callback, closeAfter ) {
				if (!Object.values(this.valid).every(val => val) || !this.schedule.every(s => this.isSchedValid(s, 'morning') && this.isSchedValid(s, 'afternoon'))) {
					callback(-1)
					return
				}

				try {
					if (this.staff.groupid < 0) {
						const group = await this.$api.groups.post({
							name: this.newGroupName
						})
						console.log(group.data[0])
						this.staff.groupid = group.data[0].id
					}

				} catch (err) {
					callback( false, (err && err.response && err.response.data && err.response.data.error) || err )
					error = true
					return
				}

				if ( this.$store.getters.salonHasUniqueAccount() || this.staff.username == 'general' ) {
					let error = false
					await this.$refs.editSalon.save().catch( err => {
						callback( false, err )
						error = true
					})
					if ( error ) {
						return
					}
				}

				let patchedMainSchedule = false
				let patch = {}
				if ( this.data && Object.keys( this.data ).length > 0 ) {
					Object.keys( this.staff ).filter( elem => elem !== "sched" && elem !== "name" ).forEach( key => {
						if ( Array.isArray( this.staff[key] ) ) {
							if ( !this.staff[key].isSame( this.data[key] ) ) {
								patch[key] = this.staff[key]
							}
						} else if ( this.staff[key] != this.data[key] && key != "password" ) {
							patch[key] = this.staff[key]
						}
					})
				} else {
					delete this.staff.id
					patch = JSON.parse( JSON.stringify( this.staff ) )
				}
				if ( this.password && this.password.length > 0 ) {
					patch.password = this.password
					patch.passwordCheck = this.passwordCheck
				}
				if ( this.$store.getters.salonHasUniqueAccount() || this.staff.username == "general" ) {
					let sched = []
					for ( let i = 0; i < this.schedule.length; i++ ) {
						sched[i] = {
							id: i + 1,
							morningop: this.schedule[i].morning ? this.hourString(60*this.hourNumber(this.schedule[i].morningop)) : "0",
							morningclos: this.schedule[i].morning ? this.hourString(60*this.hourNumber(this.schedule[i].morningclos)) : "0",
							afternop: this.schedule[i].afternoon ? this.hourString(60*this.hourNumber(this.schedule[i].afternop)) : "0",
							afternclos: this.schedule[i].afternoon ? this.hourString(60*this.hourNumber(this.schedule[i].afternclos)) : "0",
						}
					}
					try {
						const response = await this.$api.schedules.patch( sched )
						this.$store.dispatch( "reloadSchedules" )
						patchedMainSchedule = true
					} catch ( error ) {
						console.log( error )
						callback( false, error.response.data.error )
						return
					}
				} else {
					let schedule = []
					Object.keys( this.schedule ).forEach( i => {
						const sched = this.schedule[i]
						let array = [ parseInt(i) + 1 ]
					//	if ( sched.morning || sched.afternoon ) {
							if ( sched.morning ) {
								array.push( sched.morningop ? this.hourNumber(sched.morningop) * 60 : null, sched.morningclos ? this.hourNumber(sched.morningclos) * 60 : null )
							} else {
								array.push( 0, 0 )
							}
							if ( sched.afternoon ) {
								array.push( sched.afternop ? this.hourNumber(sched.afternop) * 60 : null, sched.afternclos ? this.hourNumber(sched.afternclos) * 60 : null )
							} else {
								array.push( 0, 0 )
							}
							schedule.push( array )
					//	}
					})
					if ( !this.data || !schedule.isSame( this.staff.schedule || [] ) ) {
						patch.schedule = schedule
					}
				}

				if ( patch.disabled_services ) {
					patch.disabled_services = patch.disabled_services.filter(s => s.id)
					if ( patch.disabled_services.length == 0 ) {
					//	delete patch.disabled_services
					} else {
						patch.disabled_services = patch.disabled_services.map( service => service.id )
					}
				}

				console.log( this.staff )
				console.log( patch )

				if ( Object.keys( patch ).length > 0 ) {
					if ( this.data && this.data.id > 0 ) {
						this.$api.worker.patch( this.data.id, patch ).then( response => {
							this.password = ""
							this.passwordConfirm = ""
							this.passwordCheck = ""
							callback( true )
							if ( closeAfter ) {
								setTimeout( () => this.$emit( 'close' ), 1250 )
							}
						}).catch( error => callback( false, error.response.data.error ) )
					} else {
						this.$api.workers.post( patch ).then( response => {
							this.password = ""
							this.passwordConfirm = ""
							this.passwordCheck = ""
							callback( true )
							if ( closeAfter ) {
								setTimeout( () => this.$emit( 'close' ), 1250 )
							}
						}).catch( error => callback( false, error.response.data.error ) )
					}
				} else if ( patchedMainSchedule ) {
					callback( true )
					if ( closeAfter ) {
						setTimeout( () => this.$emit( 'close' ), 1250 )
					}
				} else {
					callback( -1 )
					if ( closeAfter ) {
						this.$emit( 'close' )
					}
				}
			},
			hourString( hour ) {
				if ( !hour ) {
					return null
				}
				var h = Math.floor( hour / 60 )
				var m = hour - h * 60
				if ( h < 10 ) { h = "0" + h }
				if ( m < 10 ) { m = "0" + m }
				return h + ":" + m
			},
			hourNumber( str ) {
				if (!str) return null
				if ( typeof str === "object" ) {
					return parseInt(str.HH) + parseInt(str.mm) / 60.0
				}
				if ( typeof str === "string" ) {
					var matches = str.match( /(\d*):(\d*)/ )
					if ( matches != null ) {
						return parseInt( matches[1] ) + ( parseInt( matches[2] ) / 60.0 )
					}
				}
				if ( typeof str === "number" ) {
					return str / 60
				}
				return null
			},
			hourStringPad( hour ) {
				if ( hour.indexOf( ":" ) == 1 ) {
					return "0" + hour
				}
				return hour
			},
			updateSchedule() {
				let mainSchedule = this.$store.getters.getSchedules().sort( ( a, b ) => a.id - b.id )
				let schedule = []
				if ( !mainSchedule || mainSchedule.length == 0 ) {
					this.schedule = [
						{ id: 1, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
						{ id: 2, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
						{ id: 3, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
						{ id: 4, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
						{ id: 5, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
						{ id: 6, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
						{ id: 7, morningop: 0, morningclos: 0, afternop: 0, afternclos: 0 },
					]
					return
				}
				for ( let i = 0; i < mainSchedule.length; i++ ) {
					let sched = mainSchedule[i]
					if ( !this.isSalon && ( !sched.morningop || sched.morningop == "0" ) && ( !sched.morningclos || sched.morningclos == "0" ) && ( !sched.afternop || sched.afternop == "0" ) && ( !sched.afternclos || sched.afternclos == "0" ) ) {
						schedule.push( "disabled" )
					} else {
						let staffSched = { morning: false, afternoon: false, morningop: null, morningclos: null, afternop: null, afternclos: null }
						let ssched = null
						if ( this.isSalon ) {
							if (typeof sched.morningop === 'number')
								sched.morningop = Math.ceil(sched.morningop / 60) + ':' + (sched.morningop % 60)
							if (typeof sched.morningclos === 'number')
								sched.morningclos = Math.ceil(sched.morningclos / 60) + ':' + (sched.morningclos % 60)
							if (typeof sched.afternop === 'number')
								sched.afternop = Math.ceil(sched.afternop / 60) + ':' + (sched.afternop % 60)
							if (typeof sched.afternclos === 'number')
								sched.afternclos = Math.ceil(sched.afternclos / 60) + ':' + (sched.afternclos % 60)

							staffSched.morningop = this.hourStringPad( sched.morningop )
							staffSched.morningclos = this.hourStringPad( sched.morningclos )
							staffSched.afternop = this.hourStringPad( sched.afternop )
							staffSched.afternclos = this.hourStringPad( sched.afternclos )
							if ( sched.morningop && sched.morningop != "0" && sched.morningclos && sched.morningclos != "0" ) {
								staffSched.morning = true
							}
							if ( sched.afternop && sched.afternop != "0" && sched.afternclos && sched.afternclos != "0" ) {
								staffSched.afternoon = true
							}
						} else if ( this.staff.schedule ) {
							for ( let j = 0; j < this.staff.schedule.length; j++ ) {
								if ( this.staff.schedule[j][0] == sched.id ) {
									ssched = this.staff.schedule[j]
								//	staffSched.checked = true
									break
								}
							}
							if ( ssched ) {
								staffSched.morningop = this.hourString( ssched[1] )
								staffSched.morningclos = this.hourString( ssched[2] )
								staffSched.afternop = this.hourString( ssched[3] )
								staffSched.afternclos = this.hourString( ssched[4] )
								if ( ssched[1] !== 0 && ssched[2] !== 0 ) {
									staffSched.morning = true
								}
								if ( ssched[3] !== 0 && ssched[4] !== 0 ) {
									staffSched.afternoon = true
								}
							}
						} else {
							staffSched.morning = true
							staffSched.afternoon = true
						}
						staffSched.morningopMain = this.hourStringPad( sched.morningop )
						staffSched.morningclosMain = this.hourStringPad( sched.morningclos )
						staffSched.afternopMain = this.hourStringPad( sched.afternop )
						staffSched.afternclosMain = this.hourStringPad( sched.afternclos )
						schedule.push( staffSched )
					}
				}
				this.schedule = schedule
			}
		},
		watch: {
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' );
							this.$refs.deleteConfirm.$off( 'close' );
							this.showDeleteConfirm = false;
							this.deleteWorker( callback );
						});
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' );
							this.$refs.deleteConfirm.$off( 'close' );
							this.showDeleteConfirm = false;
							callback( -1 );
						});
					});
				}
			},
			data() {
				this.staff = Object.assign( {
					id: this.$t( "editstaff.automatic" ),
					fname: "",
					lname: "",
					admin: false,
					disabled_services: [],
					theme: "",
					color: "",
					password: "",
					username: "",
					email: "",
					hidden: false,
					schedule: [],
					disabled_notifications: [],
					locale: "",
					phone: "",
					overflow_open: null,
					overflow_close: null
				}, this.data )
				if ( !this.staff.disabled_services ) {
					this.staff.disabled_services = []
				} else {
					this.staff.disabled_services = this.staff.disabled_services.map( id => this.$store.getters.getService( id ) )
				}
				this.updateSchedule()
			}
		},
		mounted() {
			if ( this.data ) {
				this.updateSchedule()
			}
			if ( !this.staff.disabled_services ) {
				this.staff.disabled_services = []
				} else {
				this.staff.disabled_services = this.staff.disabled_services.map( id => this.$store.getters.getService( id ) )
			}
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/editstaff.scss"/>
