export default function( domain ) {
	if ( domain == "coachs" ) {
		return {
			themes: {
				"coach-1": "#eb6159",
				"coach-3": "#a1bd6c",
				"coach-4": "#b085be",
			},
			colors: [
				"#FEC8D8",
				"#FFB7B2",
				"#febf9f",
				"#FFDFD3",
				"#f3c9a7",
				"#ffe6b0",
				"#e7e5b6",
				"#cfe8a4",
				"#c8f5e4",
				"#bbeaff",
				"#b2c7f4",
				"#c7c2e8"
			],
		}
	}
	return {
		themes: {
			"nightcity": "#a23754",
			"pink": "#9d3768",
			"wine": "#993d55",
			"coraltree": "#ad3945",
			"purple": "#685e7d",
			"lightred": "#6d4a75",
	// 		"red": "#b02142",
			"ivoire": "#6a844a",
			"eggplant": "#75805f",
			"finlandia": "#5d7361",
	// 		"deeppurple": "#524775",
			"grey": "#90484d",
			"coffee":"#795a5a",
			"blue": "#735e5e",
		},
		colors: [
			"#FEC8D8",
			"#FFB7B2",
			"#febf9f",
			"#FFDFD3",
			"#f3c9a7",
			"#ffe6b0",
			"#e7e5b6",
			"#cfe8a4",
			"#c8f5e4",
			"#bbeaff",
			"#b2c7f4",
			"#c7c2e8"
		],
	}
}

// 	"#d6deb9",
// 	"#f5e6d3",
// 	"#ddf3c2",
// 	"#f0e3e6",
